import React from "react";
import "./RecipesDisplay.css";
import Tile from "../../../../components/Tile/Tile";
import { allRecipes } from "../../../../recipes/recipes";

const RECIPE_PATH = "/recipe/";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max) + 1; // zero is not valid
}

const numberOfGenericImages = 13; // the number of images added under public/generic/

const reversedRecipes = allRecipes.reverse() // We use reversed recipes so the newest appear at the top

const tilesList = reversedRecipes.map((recipe) => (
  <Tile
    key={recipe.id}
    imagePath={
      recipe.banner === ""
        ? `generic/gen${getRandomInt(numberOfGenericImages)}.webp`
        : recipe.banner
    }
    title={recipe.title}
    text={recipe.description}
    link={RECIPE_PATH + recipe.id}
    newRecipe={recipe.newRecipe}
  />
));

function RecipesDisplay() {
  return (
    <div className="recipes-wrapper">
      <div className="recipes-container">
        <h2>Explore Recipes</h2>
        <div className="recipes-carousel-wrapper">
          <div className="recipes-carousel">{tilesList}</div>
        </div>
      </div>
    </div>
  );
}

export default RecipesDisplay;
