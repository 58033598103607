import React from "react";
import "./Ingredient.css";
import { allIngredients } from "../../../recipes/ingredients";

function getIngredientForId(id: number) {
  const ingredientMatch = allIngredients.filter((ingredient) => ingredient.id === id);
  return ingredientMatch[0];
}

type IngredientProps = {
  id: number;
};

function IngredientComponent(props: IngredientProps) {
  const { id } = props;
  const matchedIngredient = getIngredientForId(id);
  const { name, description, aliases } =
    matchedIngredient;

  const ingredientsAliases = aliases.map((alias) => (
    <React.Fragment key={alias}>"{alias}" </React.Fragment>
  ));

  return (
    <div className="ingredients-wrapper">
      <div className="ingredients-container">
        <h1>{name}</h1>
        <p>Also known as: {ingredientsAliases}</p>
        <p>
          <i>{description}</i>
        </p>
      </div>
    </div>
  );
}

export default IngredientComponent;
