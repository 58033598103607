import { IngredientDescription } from "./types";

const jeera: IngredientDescription = {
  id: 1,
  dateAdded: "",
  banner: "cumin.webp",
  name: "Jeelakara",
  aliases: [
    "Jeera",
    "Cumin Seeds"
  ],
  description: "Cumin, a staple in South Indian cooking, adds a warm, nutty flavor and subtle bitterness to dishes like sambar, rasam, and various vegetable curries, enhancing their aromatic profiles. Its aromatic seeds are often toasted and ground to create a base for many traditional South Indian spice blends, contributing to the distinct taste of the region's cuisine."
};

const allIngredients: IngredientDescription[] = [
  jeera
]

export { allIngredients };
