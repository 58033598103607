import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
      </div>
    </div>
  );
}

export default Footer;