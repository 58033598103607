import React from 'react';
import "./Poster.css";

function Poster(){

    const imagePath = "poster2.png"

    return(
        <div className='poster-wrapper'>
            <div className='poster-container'>
                <div className='poster-image-container'>
                    <img alt="" className='poster-image' src={`${process.env.PUBLIC_URL}/${imagePath}`}/>
                </div>
                <div className='poster-text-container'>
                    <h1 className='poster-text'>A virtual cookbook of cherished family recipes.</h1>
                </div>
            </div>
        </div>
    )
}

export default Poster;