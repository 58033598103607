import React from "react";
import "./IngredientPage.css";

import Nav from "../../components/Nav/Nav";
import IngredientComponent from "../Ingredients/component/Ingredient";
import Footer from "../../components/Footer/Footer";

function IngredientPage(){

    const urlPath = window.location.pathname;
    const recipeNumber = +urlPath.split("/")[2]
    
    return(
        <div>
            <Nav path={"home"}/>
            <IngredientComponent id={recipeNumber}/>
            <Footer />
        </div>
    )
}

export default IngredientPage;