import React from "react";
import "./AboutPage.css";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";

function AboutPage() {
  return (
    <div>
      <Nav path="home" />
      <div className="about-wrapper">
        <div className="about-container">
          <h1>Welcome to our Family's Online Cookbook!</h1>
          <p>
            The idea of the website is to share and preserve the recipes that
            define our culinary heritage.
          </p>
          <p>
            While we will undoubtedly host a lot of traditional South Indian
            recipes, all recipes that you would like to share or archive are
            welcome.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AboutPage;
