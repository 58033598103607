import React from "react";
import "./Nav.css";
import { useNavigate } from "react-router-dom";


function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

type NavProps = {
  path: string;
}

function Nav(props:NavProps) {
  const { path } = props;

  const navigate = useNavigate();
  return (
    <div className="nav-wrapper">
      <div className="nav-container">
        <div className="nav-logo">
            <img alt="" 
                className="nav-logo-image"
                src={`${process.env.PUBLIC_URL}/vanta-vibe-logo.png`}
                onClick={() => navigate("/")}
            />
        </div>
        <div className="nav-menu-container">
          {path === "home" ? <h1 onClick={() => navigate("/")}>{capitalizeFirstLetter(path)}</h1> : <h1 onClick={() => navigate(`/${path}`)}>{capitalizeFirstLetter(path)}</h1>}
            
        </div>
      </div>
    </div>
  );
}

export default Nav;
