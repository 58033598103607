import { RecipeDescription } from "./types";

const dahiVadaRecipe: RecipeDescription = {
  id: 1,
  newRecipe: false,
  dateAdded: "",
  banner: "vada.webp",
  title: "Dahi Vada",
  description:
    "Lentil donuts soaked in creamy yogurt, infused with aromatic spices, and garnished with a flavorful medley of fried seasonings, creating a delectable Indian delicacy. Each vada is fried to perfection and then immersed in a tangy yogurt brine before being served with a final flourish of crispy spice topping.",
  preparationTime: "5 hours",
  cookTime: "2 hours",
  ingredients: [
    {
      name: "2 cups Urad Dhal (whole - no skin)",
      group: "Vada",
    },
    {
      name: "1/2 Teaspoon Salt or to taste",
      group: "Vada",
    },
    {
      name: "1/2 Teaspoon Jeera (Cumin)",
      group: "Vada",
      link: "1",
    },
    {
      name: "About 80ml of Water",
      group: "Vada",
    },
    {
      name: "750g Plain Yoghurt (not sour)",
      group: "Dahi",
    },
    {
      name: "1-2 Green Chillies",
      group: "Dahi",
    },
    {
      name: "1 Teaspoon Urad Dhal (split)",
      group: "Dahi",
    },
    {
      name: "1 Teaspoon Channa Dhal",
      group: "Dahi",
    },
    {
      name: "1/2 Teaspoon Mustards seeds",
      group: "Dahi",
    },
    {
      name: "Pinch of Hing",
      group: "Dahi",
    },
    {
      name: "Pinch of chopped Ginger",
      group: "Dahi",
    },
    {
      name: "10 Curry leaves",
      group: "Dahi",
    },
    {
      name: "1/2 Tablespoon Semolina",
      group: "Dahi",
    },
    {
      name: "A pinch of Funugreek seed powder",
      group: "Dahi",
    },
    {
      name: "2 Dried Chillies",
      group: "Dahi",
    },
    {
      name: "Small amount of Yoghurt",
      group: "'Buttermilk' Brine",
    },
    {
      name: "Water",
      group: "'Buttermilk' Brine",
    },
    {
      name: "Pinch of Salt",
      group: "'Buttermilk' Brine",
    },
    {
      name: "Any frying Oil",
    },
  ],
  method: [
    "1. Soak Urad Dhal in water for 4 hours. Be careful not to soak for more than 5 hours as the Vada will start to absorb more oil during frying and become oily.",
    "2. Remove the Urad Dhal and strain the water completely from them.",
    "3. Add the Dhal to a *Wet Grinder*. If you don't have a Wet Grinder, you can also use any other kind of Grinder.",
    "4. Grind the Urad Dhal. Add water very slowly to the mixture until a thick batter forms. The batter should be soft but firm enough to shape into Vada's. It should not be runny or watery.",
    "5. Add Salt to the batter at the very end and grind one last time before removing.",
    "6. Add Jeera and Semolina to the batter and mix in.",
    "7. Fry Urad Dhal, Channa Dhal, Mustard seeds, Hing, Ginger, Curry Leaves, Dried Chillies, Fenugreek seeds and set aside. Set aside a small amount of these for garnish.",
    "8. Cut Green Chillies.",
    "9. Add the fried spices and chillies to the Yoghurt and mix together. Add salt to taste.",
    "10. Make a 'Buttermilk' brine by adding a small amount of Yoghurt and salt to a bowl of water. This will be for placing Vada's in after frying so that they absorb more Yoghurt when added to the Dahi.",
    "11. Heat oil for frying to medium heat.",
    "12. Wet your hands to make forming the Vada's more easy. Form small donut shapes and place them directly into the oil.",
    "13. Fry each Vada for about 2 minutes. Remove them before they turn golden brown. This can be done in small batches.",
    "14. Place removed Vada's into the yoghurt brine for about 3 minutes. Shake them dry of excess brine and place them into the Dahi.",
    "15. After all Vada's have been added to the Dahi, add the fried spices on top as garnish.",
    "img://vada.webp",
  ],
};

const avocadoPachadiRecipe: RecipeDescription = {
  id: 2,
  newRecipe: false,
  banner: "avocadopachadi.webp",
  dateAdded: "",
  title: "Avocado Pachadi",
  description:
    "A creamy blend of ripe avocado, roasted peanuts, and tangy tomatoes, infused with spicy green chilies. Topped with Popu.",
  preparationTime: "15 minutes",
  cookTime: "0",
  ingredients: [
    {
      name: "1/2 Teaspoon Jeelakara",
    },
    {
      name: "4 Green Chillies",
    },
    {
      name: "4 Red Mirchi",
    },
    {
      name: "2 to 3 Tablespoons of Peanuts",
    },
    {
      name: "2 to 4 Tomatoes",
    },
    {
      name: "Chinta Tokku Lemon (large size)",
    },
    {
      name: "2 Avocados",
    },
    {
      name: "Minapappu",
      group: "Popu",
    },
    {
      name: "Aavalu",
      group: "Popu",
    },
    {
      name: "Endu Mirchi",
      group: "Popu",
    },
    {
      name: "Oil",
      group: "Popu",
    },
  ],
  method: [
    "1. Roast peanuts, Endu Mirchi, Jeelakara to make Popu",
    "2. In a little oil, fry Green Chillies and Tomatoes.",
    "3. Grind everything except Avocado and Popu.",
    "4. Add Avocado and Popu to ground ingredients.",
  ],
};

const charuRecipe: RecipeDescription = {
  id: 3,
  newRecipe: false,
  banner: "charu.webp",
  dateAdded: "",
  title: "Charu",
  description:
    "A tantalizing soup bursting with the zing of tomatoes, tamarind, and aromatic spices. Served piping hot, this comforting broth is a perfect harmony of tangy, spicy, and savory notes.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "2 to 3 cups of Water",
    },
    {
      name: "Salt",
    },
    {
      name: "1 spoon Chintapandu (Tamarind Paste)",
    },
    {
      name: "1/2 Teaspoon Pasupu",
    },
    {
      name: "Curry leaves",
    },
    {
      name: "Tomato (Optional)",
    },
    {
      name: "Cumin",
      group: "Popu",
    },
    {
      name: "Mustard Seeds",
      group: "Popu",
    },
    {
      name: "Red Chilli",
      group: "Popu",
    },
    {
      name: "Garlic",
      group: "Popu",
    },
  ],
  method: [
    "1. Boil Water for 5 minutes and add Rasam Powder",
    "2. Boil for a further 2 minutes.",
    "3. Add Chintapandu",
    "4. Make Popu by frying Cumin, Mustard Seeds, Red Chilli and lots of Garlic.",
    "5. Add Popu to Charu.",
  ],
};

const allepyStyleAvialRecipe: RecipeDescription = {
  id: 4,
  newRecipe: false,
  banner: "",
  dateAdded: "",
  title: "Allepy Avial (Kerala Style)",
  description:
    "A traditional Kerala delicacy where a creamy coconut, green chili, and garlic paste is delicately combined with a medley of fresh vegetables, then gently steamed to perfection, before being infused with the richness of coconut oil, the aroma of curry leaves, the warmth of cumin seeds, and the tanginess of yogurt, resulting in a harmonious symphony of flavors.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "Fresh coconut",
    },
    {
      name: "Green Chilli",
    },
    {
      name: "Haldhi",
    },
    {
      name: "Garlic",
    },
    {
      name: "1 small onion",
    },
  ],
  method: [
    "1. Grind Coconut, Green Chillies and Garlic first",
    "2. Crush small Onion and add.",
    "3. Combine smooth paste from above with fresh cut vegetables and water and steam on low heat.",
    "4. Add salt and steam again",
    "5. Finally add 2 spoons Coconut Oil, Curry Leaves, Jeera and Yoghurt",
  ],
};

const nallaKaramRecipe: RecipeDescription = {
  id: 5,
  newRecipe: false,
  banner: "nallakaram.webp",
  dateAdded: "",
  title: "Nalla Karam",
  description:
    "Nalla Karam is a vibrant and aromatic South Indian spice blend renowned for its fiery kick and complex flavor profile. Combining roasted red chilies, fragrant curry leaves, earthy cumin seeds, and other savory ingredients, it offers a tantalizing balance of heat and depth. Perfect for enhancing the taste of various dishes, Nalla Karam adds a distinctively rich and spicy dimension to traditional Telugu cuisine.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "40-50 Dry Chillies",
    },
    {
      name: "A handful of Curry Leaves",
    },
    {
      name: "3 Tablespoons Dhaniyalu",
    },
    {
      name: "2 Tablespoons Mina pa pappu",
    },
    {
      name: "2 Tablespoons Senaga Pappu",
    },
    {
      name: "2 Tablespoons Nuvvlu",
    },
    {
      name: "1 bulb of Garlic",
    },
    {
      name: "1 Teaspoon Jeera",
      link: "1"
    },
    {
      name: "4cm of Tamarind",
    },
    {
      name: "Salt",
    },
  ],
  method: [
    "1. Add a little oil to a frying dish and fry Chillies",
    "2. Add a little more oil and then add in Dhaniyalu",
    "3. Fry Curry Leaves a little in a separate frying dish",
    "4. Once the Dhaniyalu are a little fried, add Senaga Pappu, Mina pa pappu, Jeera, Nuvvlu, Tamarind, Fried Curry Leaf",
    "5. Add everything to a grinder and grind into a course powder",
  ],
};

const ravvaDosaRecipe: RecipeDescription = {
  id: 6,
  newRecipe: false,
  banner: "dosa.webp",
  dateAdded: "",
  title: "Ravva Dosa",
  description:
    "Ravva Dosa is a savory South Indian crepe made from a batter consisting of semolina, rice flour, all-purpose flour, and spices like cumin seeds. Unlike traditional dosa batter, Ravva Dosa batter does not require fermentation, allowing for quick preparation. Once cooked on a hot griddle, it yields a crispy texture and is typically served with chutneys and sambar, making it a flavorful and popular breakfast or snack option in South India.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup Maida",
    },
    {
      name: "1 cup Ravva",
    },
    {
      name: "1 cup Rice Flour",
    },
    {
      name: "2 Tablespoons Curd",
    },
    {
      name: "1 Tablespoon Ginger and Green Chilli Paste",
    },
    {
      name: "1 Tablesoon Jeera",
      link: "1",
    },
    {
      name: "Salt",
    },
  ],
  method: [
    "1. To make the batter: mix Ravva, Rice Flour, Maida, Jeera.",
    "2. Add Curd, Ginger Chilli Paste and Salt.",
    "3. Add Water gradually while mixing until the batter is no longer thick.",
    "4. Prepare a wide pan on the stove and apply oil.",
    "5. To make dosa, pour batter onto the center of the pan and spread it into a flat circle with a ladle or serving spoon.",
    "6. Flip once the first side is cooked.",
  ],
};

const peanutChutneyRecipe: RecipeDescription = {
  id: 7,
  newRecipe: false,
  banner: "peanutchutney.webp",
  dateAdded: "",
  title: "Peanut Chutney",
  description:
    "Peanut Chutney is a flavorful condiment originating from South India, crafted by dry roasting peanuts until golden brown and then blending them with roasted chana dal, urad dal, green chili, cumin seeds, garlic, ginger, and tamarind. This aromatic mixture is blended into a smooth paste, with water added gradually to achieve the desired consistency. Typically served as an accompaniment to dosas, idlis, or other South Indian snacks, Peanut Chutney offers a delightful balance of nuttiness, spice, and tanginess, enhancing the overall dining experience.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup Peanuts",
    },
    {
      name: "1/2 Tablespoon Oil",
    },
    {
      name: "1 Tablespoon Chana Dhal",
    },
    {
      name: "1 Tablespoon Chana Dhal",
    },
    {
      name: "1 Tablespoon Urad Dhal",
    },
    {
      name: "1 Green Chilli (increase quantity based personal preferences)",
    },
    {
      name: "1 Tablesoon Jeera",
      link: "1",
    },
    {
      name: "4 Cloves Garlic",
    },
    {
      name: "2cm or 1 inch of Ginger (increase quantity based personal preferences)",
    },
    {
      name: "2cm or 1 inch of Tamarind",
    },
    {
      name: "1 Tirgamata",
    },
    {
      name: "Salt",
    },
  ],
  method: [
    "1. Dry roast 1 cup of Peanuts in a medium sized pan on low heat",
    "2. Dry roast until the Peanuts start to brown on the outside",
    "3. Add 1/2 Tbsp of oil to a medium sized pan on low heat",
    "4. Once the oil is warm, add 1 Tablespoon of Chana Dhal and 1 Tbsp of Urad Dhal",
    "5. Sauté the Chana Dhal and Urad Dhal with a wooden spoon until both Dhals start to brown in colour",
    "6. Chop the Green Chilli into four equal pieces and add this to the pan. Saute for 30 seconds.",
    "7. Add 1 Tsp of Cumin seeds to the pan and saute for an additional 30 seconds.",
    "8. Remove the pan from the flame and transfer the contents into a blender.",
    "9. Add the dry roasted Peanuts, 4 cloves of Garlic, 2cm Ginger and 2cm Tamarind into the blender.",
    "10. Blend the ingredients until it forms a paste. During the blending process add small quantities of water to loosen the chutney to your desired preference.",
    "11. Transfer to the chutney to your serving bowl and top with Tirgamata.",
  ],
};

const palliPodiRecipe: RecipeDescription = {
  id: 8,
  newRecipe: false,
  banner: "pallipodi.webp",
  dateAdded: "",
  title: "Palli Podi",
  description:
    "Palli Podi, or Peanut Powder, is a dry spice blend originating from South India, prepared by frying peanuts until they are golden brown and then removing the skin. Following this, a mixture of red chilies, urad dal, coriander seeds, and a touch of tamarind and salt is fried with minimal oil until aromatic. Finally, all the ingredients are ground into a coarse powder, resulting in a fragrant and flavorful condiment that adds a nutty, spicy kick to various dishes, such as rice, dosas, or idlis.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "2 cups Pallilu (Peanuts)",
    },
    {
      name: "6 Red Chillies",
    },
    {
      name: "1 Tablespoon Dhaniya",
    },
    {
      name: "1 Teaspoon Urad Dhal",
    },
    {
      name: "4 very small pieces of dry Tamarind",
    },
    {
      name: "Salt",
    },
  ],
  method: [
    "1. Fry Peanuts and remove skin.",
    "2. Fry Red Chillies, Urad Dhal, Dhaniya with very little oil.",
    "3. Add Tamarind and Salt.",
    "4. Grind all ingredients into a course powder.",
  ],
};

const garlicPodiRecipe: RecipeDescription = {
  id: 9,
  newRecipe: false,
  banner: "garlicpodi.webp",
  dateAdded: "",
  title: "Garlic Podi",
  description:
    "Garlic Podi, also known as garlic powder, is a versatile spice blend popular in South Indian cuisine, made by frying a mixture of ingredients including lentils, spices, and optionally, curry leaves, with a touch of oil until fragrant. Separately, garlic cloves are fried until golden brown. The first set of ingredients is then ground into a coarse powder. Afterward, the fried garlic cloves and salt are added to the ground mixture and ground again, resulting in a flavorful and aromatic powder that adds a punch of garlic flavor to rice, dosas, or other dishes.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "2 Large Garlic Cloves",
    },
    {
      name: "6 Red Chillies",
    },
    {
      name: "1 Cup Dhaniya",
    },
    {
      name: "1 and 1/2 Cup Urad Dhal",
    },
    {
      name: "1 Tablespoon Jeelakara",
      link: "1",
    },
    {
      name: "1/2 Teaspoon Black Pepper",
    },
    {
      name: "Curry Leaves",
    },
    {
      name: "Small piece tamarind",
    },
    {
      name: "Salt",
    },
  ],
  method: [
    "1. Fry all ingredients except garlic with a little oil.",
    "2. Fry garlic pieces seperately.",
    "3. Grind first set of ingredients.",
    "4. Add garlic and salt and grind again.",
  ],
};

const mysoreBondaRecipe: RecipeDescription = {
  id: 10,
  newRecipe: false,
  banner: "mysorebonda.webp",
  dateAdded: "",
  title: "Mysore bonda",
  description:
    "Mysore bonda is a delectable South Indian snack prepared by first grinding green chilies into a coarse paste. Then, a batter is made by combining yogurt with dry ingredients such as white flour, semolina, rice flour, and salt, along with the green chili paste and chopped coriander. After fermenting the batter for 30 minutes, small spoonfuls are dropped into hot oil and fried until golden brown, resulting in crispy and flavorful bondas, perfect for enjoying as a snack or appetizer.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup Maida",
    },
    {
      name: "1/2 cup course Semolina",
    },
    {
      name: "1/2 cup fine Rice Flour",
    },
    {
      name: "1 cup Yoghurt",
    },
    {
      name: "1 Teaspoon Jeera",
      link: "1",
    },
    {
      name: "2 Green Chillies",
    },
    {
      name: "2 Tablespoons chopped Coriander",
    },
    {
      name: "Salt",
    },
    {
      name: "Frying oil",
    },
  ],
  method: [
    "1. Wash and grind green chillies coursely.",
    "2. Take 1 cup of Yoghurt, add all the dry ingredients (white flour, Semolina, rice flour, salt) and mix.",
    "3. Add Green Chilli Paste and chopped Coriander to Yoghurt.",
    "4. Leave to ferment for 30 minutes.",
    "5. Heat oil and add a spoonful of batter for each Bonda.",
    "6. Fry each Bonda until golden brown."
  ],
};

const allamPachadiRecipe: RecipeDescription = {
  id: 11,
  newRecipe: false,
  banner: "allampachadi.webp",
  dateAdded: "",
  title: "Allam Pachadi",
  description:
    "Allam Pachadi, a traditional Andhra condiment, is made by soaking tamarind, frying fenugreek seeds, and grinding them. Ginger, jaggery, and spices are blended into a paste. This paste is cooked with a tempering of mustard seeds, cumin seeds, and spices until thickened to a reddish-brown jam-like consistency. The result is a flavorful, tangy-sweet chutney, perfect to accompany South Indian dishes.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "2-4 cups cooking oil",
    },
    {
      name: "1 cup Ginger",
    },
    {
      name: "1 cup cleaned Tamarind pulp",
    },
    {
      name: "2 cups grated Jaggery",
    },
    {
      name: "1 cup fine Red Chilli Powder",
    },
    {
      name: "1/2 cup Salt (according to taste)",
    },
    {
      name: "2 Teaspoons Turmeric",
    },
    {
      name: "1/2 cup Funugreek seeds",
    },
    {
      name: "1 Teaspoon Mustard seeds",
    },
    {
      name: "1 Teaspoon Cumin seeds",
      link: "1"
    },
    {
      name: "1 Teaspoon Hing"
    },
    {
      name: "6 Dry Red Chillies"
    }
  ],
  method: [
    "1. Soak Tamarind in boiling water for half an hour. Pour only enough water to cover the Tamarind.",
    "2. Fry Fenugreek seeds in low flame and grind into a fine powder.",
    "3. Wash Ginger, peel the skin and let it dry completely.",
    "4. Cut the Ginger into small pieces and set aside.",
    "5. Grate the Jaggery and set aside.",
    "6. Grind Salt, Ginger pieces, Tamarind pulp, grated Jaggery, Red Chilli Powder to a fine paste and put aside.",
    "7. Put the pan on the stove and add two cups of oil. When the oil is heated, add Cumin Seeds, Mustard Seeds, Dry Red Chillies, Hing and fry them to make Popu.",
    "8. Add the ground ingredients to the Popu.",
    "9. Cook for 15 minutes on low heat until it is thick and soft like jam. The color should be reddish brown once complete.",
    "10. As it is cooking add extra oil or salt if needed."
  ],
};

const aavakaiyaRecipe: RecipeDescription = {
  id: 12,
  newRecipe: false,
  banner: "aavakaiya.webp",
  dateAdded: "",
  title: "Aavakaiya",
  description:
    "A tangy and spicy delicacy from Andhra Pradesh, where succulent mango pieces are generously coated with a flavorful mixture of chili powder, fenugreek seeds, and sesame oil, then left to marinate in layers until perfectly infused with a burst of aromatic spices, offering a delightful burst of flavors with each bite.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup ground mustard seeds (Aavalu)",
    },
    {
      name: "1/2 cup of salt (or more to taste - more sour mango, more salt)",
    },
    {
      name: "1 cup '3 mangoes mirch powder' karam",
    },
    {
      name: "2 to 3 medium-sized mangoes (remove film from mango pit)",
    },
    {
      name: "1/2 cup sesame oil",
    },
    {
      name: "1 Tablespoon menthilu",
    },
    {
      name: "One large glass bowl",
    }
  ],
  method: [
    "Repeat steps 1-4 until the glass bowl is full. If using garlic the same instructions apply but less karam is added.",
    "1. Put one cup karam, ava, salt mixture into a bowl.",
    "2. Add one Tablespoon 'menthilu', fenugreek seeds (or you can sprinkle on top with each layer).",
    "3. Add 1/4 cup sesame oil to the mixture.",
    "4. Put each mango piece into the mixture and put in the (1/2 cup) of sesame oil. Make sure to put in a good amount of mixture with each mango piece.",
    "5. Once you have filled the bowl with mixture, oil and mango: pour one cup sesame oil over the mixture in the bowl and cover.",
    "<h4>Tips:",
    "- If making with senagalu (kaladhana - black chickpeas), sprinkle between layers. Also, menthilu as before.",
    "- Make sure to check 'aavalu' for rocks, etc.",
    "<h4>Bottling:",
    "- Add 1-2 Teaspoons of citric acid to the mix before bottling",
    "- Keep aavakaiya in a glass bowl for 2 days. On the third day, mix thoroughly and place in dry glass containers. If the mixture is too thick, add oil.",
    "- When bottling, but one Tablespoon of oil on the bottom of the dry container add the mixture and then put one more Tablespoon on top.",
  ],
};

const kandiPodiRecipe: RecipeDescription = {
  id: 13,
  newRecipe: false,
  banner: "kandipodi.webp",
  dateAdded: "",
  title: "Kandi Podi",
  description:
    "A fragrant and robust spice blend hailing from Andhra Pradesh, where coriander seeds, cumin seeds, red chilies, and onions are individually dry-roasted until they develop a rich hue, then finely ground into a powder, delivering a tantalizing burst of flavor when sprinkled over dishes.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 Cup Toor Dal",
    },
    {
      name: "1/2 Cup Channa Dal",
    },
    {
      name: "1/4 Cup Moong Dal",
    },
    {
      name: "3 Dry Red Chillies (adjust to your taste)",
    },
    {
      name: "2 Teaspoons Cumin Seeds",
      link: "1"
    },
    {
      name: "1 Teaspoon Salt (or to taste)",
    },
    {
      name: "1/2 Teaspoon Hing",
    }
  ],
  method: [
    "1. Fry all ingredients except Salt and Hing seperately until they change color. Do this without oil.",
    "2. Let the ingrendients cool completely.",
    "3. Grind all of the ingredients together into a fine powder."
  ],
};

const kooraKaramRecipe: RecipeDescription = {
  id: 14,
  newRecipe: false,
  banner: "koorakaram.webp",
  dateAdded: "",
  title: "Koora Karam",
  description: "A zesty spice mix originating from South India, where Channa Dal and Urad Dal are delicately fried in oil until golden, then combined with an array of aromatic ingredients, creating a vibrant blend that, when ground to a fine powder, adds a burst of flavor and heat to a variety of vegetable dishes.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 Cup Channa Dal",
    },
    {
      name: "3/4 Cup Urad Dal",
    },
    {
      name: "4 Red Chillies (adjust to your taste)",
    },
    {
      name: "1/4 Cup Coriander Seeds",
    },
    {
      name: "1 Tablespoon Cumin Seeds",
      link: "1"
    },
    {
      name: "1/2 Fenugreek Seeds",
    },
    {
      name: "2 Teaspoons Sesame Seeds",
    },
    {
      name: "Salt",
    }
  ],
  method: [
    "1. Put 2 Teaspoons of oil in the pan and fry Channa Dal and Urad Dal on a low flame.",
    "2. Add all ingredients and fry until the color changes.",
    "3. Let the ingrendients cool completely.",
    "4. Grind ingredients together into a fine powder."
  ],
};

const ulliKaramRecipe: RecipeDescription = {
  id: 15,
  newRecipe: false,
  banner: "ullikaram.webp",
  dateAdded: "",
  title: "Ulli Karam",
  description:
    "A piquant condiment originating from South India, where a symphony of flavors is created by frying coriander seeds, cumin seeds, red chilies, and onions in oil until aromatic, then blending them into a smooth chutney-like mixture, perfect for enhancing the taste of dishes like Kakara Kaya, Vankaya, Sorakaya, Potato, Dondakaya, and Chikkudukaya in the traditional Ullikaram Kooralu.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 Large-sized Onion",
    },
    {
      name: "3 Red Chillies",
    },
    {
      name: "1 Teaspoon Coriander Seeds",
    },
    {
      name: "1 Teaspoon Cumin Seeds",
      link: "1"
    },
    {
      name: "Salt",
    }
  ],
  method: [
    "1. Put two Teaspoons of oil in a pan. Fry Coriander Seeds, Cumin Seeds, Red Chillies and Onion Pieces.",
    "2. Add Salt and cook for 4 minutes.",
    "3. Let the ingrendients cool completely.",
    "4. Grind ingredients together into a soft mixture (like a chutney).",
    "This can be used in Ullikaram Kooralu: Kakara Kaya (Bitter Gourd), Vankaya, Sorakaya, Potato, Dondakaya, Chikkudukaya"
  ],
};

const instantKalakand: RecipeDescription = {
  id: 16,
  newRecipe: true,
  banner: "kalakand.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Instant Kalakand",
  description: "A quick and indulgent treat made by combining fresh cream, sugar, and milk powder, then microwaving until it transforms into a creamy, light brown mixture with the classic Kalakand texture, topped with chopped nuts for added crunch and flavor, and easily customizable into desired pieces once cooled, offering a delightful taste of traditional Indian sweet in no time.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "300ml Fresh Cream"
    },
    {
      name: "3/4 Cup Sugar"
    },
    {
      name: "3 Cups Milk Powder"
    },
    {
      name: "A handful of nuts for garnish"
    }
  ],
  method: [
    "1. Take a big bowl and add fresh cream and sugar.",
    "2. Gently mix together until sugar dissolves.",
    "3. Add milk powder and mix it in.",
    "4. Place mixture in microwave and heat for 4 minutes.",
    "5. Stir mixture once and microwave for another 2 minutes.",
    "6. Stir mixture again and microwave again for another 2 minutes.",
    "7. The mixture will turn light brown and have a kalakan texture.",
    "8. Tip the mixture into a plate sprinkle the chopped nuts on top.",
    "9. Place baking paper on top and set it flat.",
    "10. Once cooled, but into desired pieces.",
    "<h4>Tips:",
    "- Microwave timing can differ. Keep microwaving and stiring until it has a Kalakand consistency.",
  ]
}

const challaAttu: RecipeDescription = {
  id: 17,
  newRecipe: true,
  banner: "challaAttu.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Challa Attu",
  description: "A savory delight, where a batter of ground lentils and spices is meticulously fermented for nine hours, then enhanced with a pinch of turmeric and salt before being cooked to perfection, topped with a medley of onions, cumin seeds, and green chilies soaked in batter for added flavor, resulting in crispy, golden-brown pancakes that tantalize the taste buds with every bite.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "2 cups rice"
    },
    {
      name: "1/2 cups Urad Dal"
    },
    {
      name: "1 tablespoon Saggu Biyyam"
    },
    {
      name: "Curd"
    },
    {
      name: "Turmeric"
    },
    {
      name: "Salt"
    }
  ],
  method: [
    "1. Grind all ingredients without water and as curd as needed.",
    "2. Grind into fine paste and add turmeric and salt into batter.",
    "3. Ferment for 9 hours. Before making Attu and a pinch of baking soda and leave for another 10 minutes.",
    "Toppings: onion, jeera, green chillies (soak in batter while fermenting)",
  ]
}

const raagiDosa: RecipeDescription = {
  id: 18,
  newRecipe: true,
  banner: "raagiDosa.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Raagi Dosa",
  description: "A wholesome and nutritious South Indian delicacy prepared by soaking ragi (finger millet) for nine hours, then grinding it into a smooth batter, allowing it to ferment overnight to develop its characteristic tangy flavor and airy texture, resulting in golden-brown, crispy dosas that are not only delicious but also packed with the goodness of ragi, making it a perfect choice for a wholesome breakfast or snack.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup Urad Dal"
    },
    {
      name: "1 cups Raagi (washed)"
    },
    {
      name: "1/4 cup rice"
    },
    {
      name: "2 spoons Subudana"
    }
  ],
  method: [
    "1. Soak for 9 hours and grind ingredients in mixer.",
    "2. Leave overnight before making dosa."
  ]
}

const oatsAndChickpeaDosa: RecipeDescription = {
  id: 19,
  newRecipe: true,
  banner: "oatsAndChickpeaDosa.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Oats and Chickpea Dosa",
  description: "A healthy twist to the traditional dosa recipe, where oats, chickpeas, and godhuma rava (wheat rava) are soaked to perfection before being blended into a smooth batter, yielding dosas that are not only crispy and flavorful but also rich in fiber and protein; optionally topped with onions and green chilies for an extra burst of taste and texture.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cups oats"
    },
    {
      name: "1/2 cup chickpea"
    },
    {
      name: "1 cup Godhuma Rava"
    }
  ],
  method: [
    "1. Soak oats for 1 hour, chickpeas for 6 hours and Godhuma Rava for 2 hours.",
    "2. Make Dosa, optionally adding onion and green chilli as toppings"
  ]
}

const vegetableFritters: RecipeDescription = {
  id: 20,
  newRecipe: true,
  banner: "vegetableFritters.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Vegetable Fritters",
  description: "A wholesome blend of senega pappu, oats, chopped corn, onions, cabbage, spinach, boiled green peas, potatoes, and carrots, seasoned with aromatic spices like jeera powder, coriander powder, and turmeric, then mixed with fresh coriander and salt to taste, before being shaped into patties and shallow-fried to crispy perfection in a pan, resulting in savory and nutritious fritters bursting with flavors and textures.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup senega pappu"
    },
    {
      name: "1 cups oats"
    },
    {
      name: "chopped corn"
    },
    {
      name: "chopped onion"
    },
    {
      name: "chopped cabbage"
    },
    {
      name: "5 or more green chillies"
    },
    {
      name: "chopped spinach"
    },
    {
      name: "boiled green peas"
    },
    {
      name: "boiled potatoes"
    },
    {
      name: "boiled carrots"
    },
    {
      name: "1/4 spoon jeera powder"
    },
    {
      name: "1/4 spoon coriander powder"
    },
    {
      name: "A pinch of turmeric powder"
    },
    {
      name: "Fresh coriander"
    },
    {
      name: "Salt to taste"
    }
  ],
  method: [
    "1. After boiling green peas, potatoes and carrots, mash them together coarsely.",
    "2. Mix all ingredients together and form patties.",
    "3. Shallow fry in a pan with oil."
  ]
}

const ravaIdli: RecipeDescription = {
  id: 21,
  newRecipe: true,
  banner: "ravaIdli.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Instant Rava Idli",
  description: "A quick and convenient take on the classic South Indian dish, where a flavorful mix of mustard seeds and jeera are sautéed in oil before blending with a medley of vegetables and sooji (semolina), then combined with a yogurt-water mixture seasoned with salt, left to rest for 30 minutes, and finally enriched with a touch of Eno for that airy texture; steamed to perfection in just 20 minutes, yielding soft and fluffy idlis perfect for a hearty breakfast.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup Sooji"
    },
    {
      name: "1/2 cup yoghurt"
    },
    {
      name: "1/2 cup onion"
    },
    {
      name: "1/2 cup carrot"
    },
    {
      name: "1 green chilli"
    },
    {
      name: "1 and 1/2 teaspoon oil"
    },
    {
      name: "A pinch of ginger"
    },
    {
      name: "5 chashew nuts"
    }
  ],
  method: [
    "1. Add oil in a pan and add Jeera and mustard.",
    "2. Add all ingredients except salt and yoghurt. Add veggies first one-by-one and Sooji last.",
    "3. Mix Yoghurt with a 1/2 cup of water and salt and beat it together.",
    "4. Let the Rava Mixture cool down before adding the yogurt mixture.",
    "5. Leave for 30 minutes. Add 1 Teaspoon Eno and add water until it has an idli batter consistency.",
    "6. Cook for 10 minutes on high and then 10 minutes on low",
    "- For 1 1/2 cup of Sooji you can make 16 Idlis approximately. Pulla Perugu is good for this recipe."
  ]
}

const palakOatsDosa: RecipeDescription = {
  id: 22,
  newRecipe: true,
  banner: "spinachDosa.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Palak Oats Dosa/Wrap",
  description: "A vibrant and nutritious twist to the traditional dosa, where oats are soaked and ground with fresh spinach, ginger, garlic, green chilies, jeera, and salt, creating a vibrant green batter bursting with flavor and health benefits; spread onto a hot griddle to make dosas, then adorned with grated carrot, chopped cabbage, onion, and coriander toppings, folded and fried to crispy perfection, resulting in wholesome and delicious dosas that are as visually appealing as they are tasty.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 big palak leaves (boiled and ground)"
    },
    {
      name: "Oats"
    },
    {
      name: "Garlic"
    },
    {
      name: "Ginger"
    },
    {
      name: "Green chilli"
    },
    {
      name: "Jeera"
    },
    {
      name: "Grated Carrot"
    },
    {
      name: "Chopped Cabbage"
    },
    {
      name: "Chopped Onion"
    },
    {
      name: "Coriander"
    },
    {
      name: "Salt"
    }
  ],
  method: [
    "1. Soak oats for 1 hours.",
    "2. Grind soaked oats together with spinach, ginger, garlic, green chillies, jeera and salt.",
    "3. Make Dosa with batter and add grated carrot, chopped cabbage, onion, coriander as toppings.",
    "4. Fold it and let it fry for a few minutes."
  ]
}

const nallakaram: RecipeDescription = {
  id: 23,
  newRecipe: true,
  banner: "nallakaram2.webp",
  author: "Rathna",
  dateAdded: "",
  title: "Nalla Karam",
  description: "A traditional spice blend where a symphony of flavors is crafted by individually frying ingredients like coriander seeds, cumin seeds, black gram, Bengal gram, and sesame seeds until aromatic, then grinding them into a coarse powder; followed by a fine powder made from red chilies, salt, tamarind, and curry leaves; finally, blending both mixtures together with garlic and fresh cumin seeds until perfectly combined, resulting in a versatile and flavorful seasoning that adds a delightful kick to various dishes.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1/4 kg Dry Chillies"
    },
    {
      name: "1 rice cup of Rock Salt"
    },
    {
      name: "50 grams of Dhania"
    },
    {
      name: "50 grams of Jeera"
    },
    {
      name: "50 grams of minappappu"
    },
    {
      name: "50 grams of pachi senagapappu"
    },
    {
      name: "2 tablespoons of Nuvvulu"
    },
    {
      name: "2 cups of Karivepaku"
    },
    {
      name: "70 grams of Tamarind"
    },
    {
      name: "1/4 Kg garlic"
    },
    {
      name: "Salt"
    }
  ],
  method: [
    "1. Fry all ingredients except tamarind, salt and garlic. Fry separately one-by-one with little oil.",
    "2. Grind Dhaniyalu, Jeera, minappappu, senagapappu, nuvvulu together like fine coarse powder.",
    "3. Remove ground mixture and grind red chllies, salt, tamarind and Karivepaku together like fine powder",
    "4. Add everything together and add garlic, fresh jeera and grind everything together until they are well mixed"
  ]
}

const sambarPowder: RecipeDescription = {
  id: 24,
  newRecipe: true,
  banner: "sambarPowder.webp",
  author: "Ammamma",
  dateAdded: "",
  title: "Sambar Powder",
  description: "A fragrant and aromatic blend of channa dal, toor dal, urad dal, coriander seeds, cumin seeds, red chilies, black pepper, fenugreek seeds, hing, rice, and dried coconut, meticulously roasted without oil until they release their enticing aroma; then ground into a fine powder, ready to infuse the authentic flavors of South Indian cuisine into your sambar dishes with every spoonful.",
  preparationTime: "0",
  cookTime: "0",
  ingredients: [
    {
      name: "1 cup channa daal"
    },
    {
      name: "1 cup toor daal"
    },
    {
      name: "1 tablespoon urad daal"
    },
    {
      name: "3/4 cup coriander seeds"
    },
    {
      name: "1/2 cup cumin seeds"
    },
    {
      name: "4 red chillies"
    },
    {
      name: "2 teaspoons black pepper"
    },
    {
      name: "2 teaspoons fenugreek seeds"
    },
    {
      name: "1 teaspoon hing"
    },
    {
      name: "1/2 cup rice"
    },
    {
      name: "1/2 cup dried coconut"
    }
  ],
  method: [
    "1. Fry all ingredients seperately and without oil on low flame until the spices turn aromatic.",
    "2. Let it cool completely and make powder."
  ]
}

const allRecipes: RecipeDescription[] = [
  dahiVadaRecipe,
  avocadoPachadiRecipe,
  charuRecipe,
  allepyStyleAvialRecipe,
  nallaKaramRecipe,
  ravvaDosaRecipe,
  peanutChutneyRecipe,
  palliPodiRecipe,
  garlicPodiRecipe,
  mysoreBondaRecipe,
  allamPachadiRecipe,
  aavakaiyaRecipe,
  kandiPodiRecipe,
  kooraKaramRecipe,
  ulliKaramRecipe,
  instantKalakand,
  challaAttu,
  raagiDosa,
  oatsAndChickpeaDosa,
  vegetableFritters,
  ravaIdli,
  palakOatsDosa,
  nallakaram,
  sambarPowder
];

export { dahiVadaRecipe, allRecipes };
