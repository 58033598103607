import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home'
import RecipePage from './pages/Recipe/RecipePage';
import IngredientPage from './pages/Ingredients/IngredientPage';
import AboutPage from './pages/About/AboutPage';
import NotFoundPage from './pages/NotFound/NotFoundPage';

function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="recipe/:id" element={<RecipePage />} />
      <Route path="ingredient/:id" element={<IngredientPage />} />
      <Route path="about/" element={<AboutPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
