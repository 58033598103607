import React from "react";
import "./Tile.css";
import { useNavigate } from "react-router-dom";

type TileProps = {
    imagePath: string;
    title: string;
    text: string;
    link: string;
    newRecipe: boolean;
}

function Tile(props: TileProps){
    const {imagePath, title, text, link, newRecipe} = props;
    const navigate = useNavigate();
    return(
        <div className="tile" onClick={() => navigate(link)}>
            <div className="tile-image-container">
                {newRecipe && <div className="tile-image-tag"><p>NEW!</p></div>}
                <img alt="" src={`${process.env.PUBLIC_URL}/${imagePath}`}/>
            </div>
            <div className="text-container">
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default Tile;
