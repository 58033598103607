type Ingredient = {
    name: string;
    group?: string;
    link?: string;
  };
  
  type IngredientDescription = {
    id: number;
    dateAdded: string;
    name: string;
    aliases: string[];
    description: string;
    usedIn?: string[];
    banner: string;
  };

  type MethodString = string;
  type MethodStep = string & { __orderedListMarker: true };
  type MethodImageUrl = string & { __imageUrlMarker: true };
  
  type MethodEntry = MethodString | MethodStep | MethodImageUrl;
  
  // Type guard functions
  function isOrderedListString(str: MethodEntry): str is MethodStep {
    return str.includes(". ");
  }
  
  function isImageUrlString(str: MethodEntry): str is MethodStep {
    return str.startsWith("img");
  }
  
  type RecipeDescription = {
    // Metadata
    id: number;
    dateAdded: string;
    author?: string;
    // Data
    title: string;
    description: string;
    alternateTitles?: string[];
    preparationTime: string;
    cookTime: string;
    ingredients: Ingredient[];
    method: MethodEntry[];
    banner: string;
    newRecipe: boolean;
  };
  
  export {
    type Ingredient,
    type IngredientDescription,
    type MethodString,
    type MethodStep,
    type MethodImageUrl,
    type MethodEntry,
    type RecipeDescription,
    isOrderedListString,
    isImageUrlString,
  };
  