import React from "react";
import "./Recipe.css";
import {
  Ingredient,
  isImageUrlString,
} from "../../../recipes/types";
import { allRecipes } from "../../../recipes/recipes";

function getRecipeForId(id: number) {
  const recipeMatch = allRecipes.filter((recipe) => recipe.id === id);
  return recipeMatch[0];
}

type RecipeProps = {
  id: number;
};

function Recipe(props: RecipeProps) {
  const { id } = props;
  const matchedRecipe = getRecipeForId(id);
  const { title, description, ingredients, preparationTime, cookTime, method } =
    matchedRecipe;

  // some black magic that organises ingredients by their 'group' parameter
  const groupedIngredients: { [key: string]: Ingredient[] } =
    ingredients.reduce((acc: { [key: string]: Ingredient[] }, curr) => {
      const groupKey: string = curr.group || "Ungrouped";
      acc[groupKey] = acc[groupKey] || [];
      acc[groupKey].push(curr);
      return acc;
    }, {});

  // The "Ungrouped" key is searched for and placed first if it is found
  // this is because the ingredients list looks nice when ingredients that
  // don't have a group are placed at the top of the ingredients block.
  let ingredientGroups = Object.keys(groupedIngredients);

  const ungroupedIndex = ingredientGroups.indexOf("Ungrouped");

  if (ungroupedIndex !== -1) {
    // "Ungrouped" found, move it to the front of the array
    const ungroupedItem = ingredientGroups.splice(ungroupedIndex, 1)[0];
    ingredientGroups.unshift(ungroupedItem);
  }

  function RenderedIngredientsList(ingredientsList: Ingredient[]) {
    const ingredientItems = ingredientsList.map((ingredient) => {
      if (ingredient.link !== undefined) {
        return (
          <a className="ingredient-link" key={ingredient.name} href={`/ingredient/${ingredient.link}`}>
            <li key={ingredient.name}>{ingredient.name}</li>
          </a>
        );
      } else {
        return <li key={ingredient.name}>{ingredient.name}</li>;
      }
    });

    return (
      <div
        key={
          ingredientsList[0].group === undefined ? "" : ingredientsList[0].group
        }
      >
        <p>
          {ingredientsList[0].group === undefined
            ? ""
            : ingredientsList[0].group}
        </p>
        <ul>{ingredientItems}</ul>
      </div>
    );
  }

  const methodItems = method.map((methodEntry) => {
    if (isImageUrlString(methodEntry)) {
      return (
        <img alt=""
          key={methodEntry}
          className="method-image"
          src={`${process.env.PUBLIC_URL}/${methodEntry.slice(6)}`}
        />
      );
    } else if (methodEntry.startsWith("<h4>")) {
      return <h4 key={methodEntry}>{methodEntry.slice(4)}</h4>;
    } else {
      return <p key={methodEntry}>{methodEntry}</p>;
    }
  });

  return (
    <div className="recipe-wrapper">
      <div className="recipe-container">
        <h1>{title}</h1>
        <p>
          <i key={description}>{description}</i>
        </p>
        <div className="ingredients-box">
          <h2>Ingredients</h2>
          {ingredientGroups.map((ingredientGroup) =>
            RenderedIngredientsList(groupedIngredients[ingredientGroup])
          )}
          {preparationTime !== "0" && <strong>Preparation Time: {preparationTime}</strong>}
          <br />
          {cookTime !== "0" && <strong>Cooking Time: {cookTime}</strong>}
        </div>
        <div className="instructions-box">
          <h2>Instructions</h2>
          <ul className="instructions-list">{methodItems}</ul>
        </div>
      </div>
    </div>
  );
}

export default Recipe;
