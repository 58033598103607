import React from "react";
import "../../root.css";
import Nav from "../../components/Nav/Nav";
import Poster from "./components/Poster/Poster";
import RecipesDisplay from "./components/RecipesDisplay/RecipesDisplay";
import Footer from "../../components/Footer/Footer";

function Home() {
  return (
    <div className="root-container">
        <Nav path={"about"}/>
        <Poster />
        <RecipesDisplay />
        <Footer />
    </div>
  );
}

export default Home;
