import React from "react";
import "./NotFoundPage.css";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {

  const navigate = useNavigate();
  return (
    <div>
      <Nav path="home" />
      <div className="about-wrapper">
        <div className="about-container">
          <h1>Page you were searching for could not be found 😱</h1>
          <p onClick={() => navigate("/")}>Click here to go back to the home page</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NotFoundPage;
